<template>

  <div class="questions">

    <div
      v-for="(opt, opt_key) in list"
      :key="opt_key"      
      >
        <QuestionView 
          :data="opt" 
          @reply="solution_form = opt.id"
          />
          

        <QuestionSolutionForm
          v-if="solution_form == opt.id"   
          :question="opt.id"
          @created="createdSolution(opt, $event)"   
          />

        <div class="solutions">

          <template
            v-for="solution in opt.items_problem_solution"
            >
            
            <template v-if="opt.is_resolved">                
              <template v-if="solution.is_resolve">
                <QuestionSolution :solution="solution" :question="opt" />
              </template>
            </template>
            <template v-else>
              <QuestionSolution :solution="solution" :question="opt" />
            </template>            
          </template>
        </div>


    </div>
 
  </div>
  
</template>

<script>
  
export default {   
  components: {          
    
  },   
  props: {   
    list: {
      type: Array
    },
  }, 
  data () {
    return {     
      solution_form: false,      
    }
  },
  created() {    
   
  },
  mounted() {        
  },
  computed: {
    
  },
  methods: {
    createdSolution(opt, solution){
      opt.items_problem_solution = opt.items_problem_solution ? opt.items_problem_solution : [];
      opt.items_problem_solution.push(solution);
    }
  }
}
</script>

<style scoped>
 


.questions{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.solutions{
  margin-top: 1rem;
  margin-left: 2rem;
}
 
</style>
