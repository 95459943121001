<template>


  <div class="similar"  v-if="similar_diaries_data?.items_diary.length">


    <div class="divider">
      {{ $t('diary_info_similar_diaries') }}            
    </div>

    <div class="report_boxs reports_grid">    
      
      <GeneralDiary       
          v-for="(opt,ind) in similar_diaries_data.items_diary"    
          :key="ind"    
          :data="opt"      
          />   
      
    </div>
  </div>
  
</template>

<script setup>

const { $api }  = useNuxtApp();
 
const props = defineProps({
  diaryId: {
    type: Number
  }, 
})



const loadData = async () => {
  return await $api.getSimilarDiaries(props.diaryId);  
}


const { pending: is_loading,  data: similar_diaries_data } = await useLazyAsyncData('similar_diaries_data', async () => await loadData())


 
</script>

<style scoped>
 
 .similar{
  margin-bottom: 2rem;
 }
.report_boxs{
  justify-content: center!important;
  max-height: 210px;
  overflow: hidden;
}



</style>
