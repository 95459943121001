<template>

  <div class="like">   
    <UiButton  
      @click="like"
      :type="props.addon?.indexOf(props.id) >= 0 ? 'primary' : 'secondary'"
      :is-loading="likeLoading"
      :disabled="props.contentOwner == useAuth().getId()"
      :name="$plurals.get('like', props.cnt)"
      :cnt="props.cnt"
      /> 
  </div>
  
</template>

<script setup>

const { $likes } = useNuxtApp();

const likeLoading = ref(false);

const emits = defineEmits(['like'])

const props = defineProps({
  cnt: {
    type: Number
  }, 
  id: {
    type: Number
  },
  contentOwner: {
    type: Number,
    default: null
  },
  addon: {
    type: Array,
    default: () => []
  },
});

const like = () => {


  if(!useAuth().isAuth())
      return useAuth().goToLogin();
    
  likeLoading.value = true;
  $likes.toggle('week', props.id, props.addon?.indexOf(props.id) >= 0 ? true : false)
  .then((res) => {
    likeLoading.value = false;
    emits('like', props.id);
  })
  .catch((err) => {
    likeLoading.value = false;
    console.log(err);
  })
  // likes.value++;
  
}

 
 



</script>

<style scoped>
 
 .like{
   display: flex;
   justify-content: center;
   margin-top: 2rem;
 } 
</style>
