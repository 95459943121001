<template>
  <div class="pb limited">


    <UiContentNotExists
      v-if="diary_data?.id && ((diary_data?.is_hidden && diary_data.item_user?.id != useAuth().getId()) || diary_data?.is_remove)"
      />


    <template v-else-if="diary_data?.id && diary_data.item_user?.id">

      <DiaryViewHead
        :data="diary_data"
        @removediary="removeDiary"
        @unpublishdiary="publishDiary(1)"
        @publishdiary="publishDiary(0)"
        @follow="follow(1)"
        @unfollow="follow(0)"
        @approve="approveDiary"
        @unapprove="approveDiary"
        />

      <DiaryViewConditions :data="diary_data"/>

      <DiaryViewWeeks
        @choose="chooseWeek"
        :weeks="diary_data.items_week"
        :owner="diary_data.item_user?.id"
        :diary="diary_data.id"
        :link="diary_data.link"
        :active="week_active ? week_active.id : null"
        :defweek="week_empty_link"
        :week-empty-link="week_empty_link"
        @create="showWeekModal()"
        />


      <div v-show="diary_data?.items_week?.length && showComments" class="cpm"></div>


      <NuxtPage
        :week="week_active"
        :diary="diary_data"
        @choosecreateweek="showWeekModal"
        @removeweek="removeWeek"
        @updateweek="updateWeek"
        @canceleditweek="cancelEditWeek"
        />


      </template>

      <template v-else-if="diary_error && !is_loading">
        <UiContentNotExists />
      </template>

      <template v-else>
        <SkDiaryOpened />
      </template>


      <div :class="{'hidden': !showComments}">
        <WeekViewLike
          v-if="week_active"
          :addon="diary_data?.addon?.likes"
          :cnt="week_active.cnt_likes"
          :id="week_active?.id"
          :contentOwner="diary_data?.item_user?.id"
          @like="likeWeek"
          />
      </div>


      <DiaryViewSimilarDiaries
        :class="{'hidden': !showComments}"
        v-if="diary_data?.id"
        :diary-id="diary_data?.id"
        />


      <template v-if="diary_data?.id && week_active && (diary_data.items_problem?.length || useAuth().isOwner(diary_data.item_user.id))">

        <div :class="{'hidden': !showComments}">

          <div class="divider">
            <i class="icon-feed-problem"></i>
            {{ $t('week_view_grow_questions') }}
          </div>

          <QuestionShortForm
            v-if="useAuth().isOwner(diary_data.item_user.id)"
            :diary="diary_data.id"
            :week="week_active?.id"
            ></QuestionShortForm>

          <DiaryViewQuestionsList
            :list="diary_data.items_problem"
            ></DiaryViewQuestionsList>

        </div>

      </template>


      <div v-show="diary_data?.items_week?.length && showComments" class="cpm"></div>

      <div :class="{'hidden': !showComments}">

        <CommentWidget
          v-if="diary_data?.id && week_active?.id"
          :title="$t('diary_info_comments')"
          :type="'diary'"
          :content="week_active?.id"
          />

      </div>


      <WeekViewContextMenu
        :week="week_active"
        :diary="diary_data"
        @removediary="removeDiary"
        @removeweek="removeWeek(week_active?.id)"
        @publishdiary="publishDiary(0)"
        @unpublishdiary="publishDiary(1)"
        @addweek="showWeekModal"
        @likeweek="likeWeek(1)"
        @unlikeweek="likeWeek(0)"
        @followdiary="follow(1)"
        @unfollowdiary="follow(0)"
        />


      <DiaryViewChooseWeekFaza
        :data="diary_data"
        :active="active_week_choose"
        @close="closeWeekModal()"
        />


      <WeekEditChooseCover
        :diary-id="diary_data?.id"
        :list="week_active?.items_photo"
        :active="active_cover_choose"
        @close="closeCoverModal()"
        />

      <DiaryViewFinishedDiary
        :active="active_finished_choose"
        @close="closeFinishedDiaryModal()"
        />

  </div>
</template>




<script setup>


const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const { $api, $ga, $helper, $popup, $head , $follow} = useNuxtApp();


var link = ref(route.params.id);
var is_loaded = ref(false);
var week_active = ref(null);
var active_week_choose = ref(false);
var active_cover_choose = ref(false);
var active_finished_choose = ref(false);
var diary_id = ref($helper.extractLinkId(route.params['id']));
var route_week_id = ref(route.params['week'] ?? null);



// definePageMeta({
//   scrollToTop: false
// })

const week_empty_link = computed(() => {
  var id = null;
  if(diary_data.value?.items_week)
  for (const [wkey, w] of diary_data.value.items_week.entries()) {
    if(wkey == diary_data.value.items_week.length - 1)
      id = w.id;
  }
  return id;
})



const showComments = computed(() => {
  if(!(diary_data.value?.items_week?.length))
    return false;
  for (const m of route.matched)
    if (m.path.indexOf('edit') >= 0)
      return false;
  return true;
});



function removeWeek(id) {


  if(confirm('Delete week?'))
    $api.deleteWeek(getnumDiary(), id)
    .then(response => {
      $popup.success('Success');

      for (let w in diary_data.value.items_week)
        if (diary_data.value.items_week[w].id == id)
          diary_data.value.items_week.splice(w, 1);



      setActiveWeek();
    })
    .catch(error => {
      $popup.error('error');
    });
}


function setActiveWeek(id) {

  if(!diary_data.value?.items_week)
    return;

  week_active.value = diary_data.value.items_week.filter( (w) => {
    if(!id || typeof id === 'undefined'){
      return week_empty_link.value == w.id;
    }else{
      return w.id == id
    }
  })[0];

}

function showWeekModal() {
  active_week_choose.value = true;
}

function closeWeekModal() {
  active_week_choose.value = false;
}

function showCoverModal() {
  active_cover_choose.value = true;
}

function closeCoverModal() {
  active_cover_choose.value = false;
  showFinishedDiaryModal();
}

function showFinishedDiaryModal() {

  var count_harvests = diary_data.value?.items_week?.filter( (w) => { return w.faza == 2 }).length;
  var count_seeds = diary_data.value?.items_seed?.length;

  console.log('finished');
  console.log(count_harvests);
  console.log(count_seeds);

  if(count_harvests == count_seeds ){
    active_finished_choose.value = true;
  }

}

function closeFinishedDiaryModal() {
  active_finished_choose.value = false;
}

function chooseWeek(week) {
  // week_active.value = week;
  advCpm.applyCpmWithRedraw();
}

function getnumDiary() {
  return link.value.split('-')[0];
}



async function updateWeek(edited_week_id) {

  diary_data.value = await $api.getDiary(diary_data.value.id)

  redirectToWeek(edited_week_id);

  showCoverModal();

}


function removeDiary() {
  if(confirm('Delete diary?')){
    $api.deleteDiary(diary_data.value.id)
    .then(response => {
      $popup.success('Success');
      router.push({
        path: useAuth().user.value?.link
      });
    })
    .catch(error => {
      $popup.error('error');
    });
  }
}

function publishDiary(state) {
  diary_data.value.is_hidden = state;
   $api.patchDiary(diary_data.value.id, {
    is_hidden: state
  })
    .then(response => {
      if(state)
        $popup.success(t('diary_info_is_now_hidden'));
      else
        $popup.success(t('diary_info_is_now_visible'));
    })
    .catch(error => {
      diary_data.value.is_hidden = !state;
      $popup.error('error');
    });
}

function cancelEditWeek(edited_week_id) {
  redirectToWeek(edited_week_id);
}


function redirectToWeek(edited_week_id) {

  if(!edited_week_id || edited_week_id == week_empty_link.value){
    router.push({
      path: '/diaries/' + diary_data.value.link
    });
  }else{
    router.push({
      path: '/diaries/' + diary_data.value.link + '/week/' + edited_week_id
    });
  }

}


function likeWeek(id) {
  if(diary_data.value.addon.likes.indexOf(id) >= 0){
    diary_data.value.addon.likes.splice(diary_data.value.addon.likes.indexOf(id), 1);
    week_active.value.cnt_likes--;
  }else{
    diary_data.value.addon.likes.push(id);
    week_active.value.cnt_likes++;
  }
}

const showCreadeWeekIfEmpty = function() {
  if(diary_data.value && diary_data.value?.item_user?.id && useAuth().isAuth()){
    if(diary_data.value?.item_user?.id == useAuth().getId()){
      if(diary_data.value?.items_week.length){

      }else{
        active_week_choose.value = true;
      }
    }
  }
}

// const addImpression = function() {

//   if(import.meta.server)
//     return;

//   if(!useAuth().isAuth())
//     return;

//   if(diary_data.value?.item_user?.id == useAuth().getId())
//     return;

//   $api.postDiaryImpressions(diary_id.value)
//   .then(response => {
//     console.log('impression added');
//   })
//   .catch(error => {
//     console.log('impression error');
//   });

// }

const follow = function(state) {

  if(!useAuth().isAuth())
      return useAuth().goToLogin();

  $follow.toggle('diary', diary_data.value.id, state, {
    type: 'diary',
    id: diary_data.value.id,
    avatar: diary_data.value.avatar_small,
    name: diary_data.value.name,
    link: diary_data.value.link,
    finished: diary_data.value.max_faza == 2 && diary_data.value.items_week?.filter((w) => { return w.faza == 2 }).length == diary_data.value.items_seed?.length,
  })
  .then((response) => {
    diary_data.value.addon.follow = state;
    diary_data.value.cnt_followers += state ? 1 : -1;
  })
  .catch((error) => {
    $popup.error('Error')
  });
}

const addApproveDiary = function(brand_id) {

  if(!diary_data.value.approved?.length)
    diary_data.value.approved = [];
  diary_data.value.approved.push(brand_id);
}

const removeApproveDiary = function(brand_id) {
  if(!diary_data.value.approved?.length)
    diary_data.value.approved = [];
  diary_data.value.approved.splice(diary_data.value.approved.indexOf(brand_id), 1);
}

const approveDiary = function(state, brand_id) {

  if(!useAuth().isAuth())
      return useAuth().goToLogin();


  state ? addApproveDiary(brand_id) : removeApproveDiary(brand_id);

  $api.postDiaryApprove(diary_id.value, state)
  .then(response => {
    console.log('impression added');
    $popup.success('Success');
  })
  .catch(error => {
    !state ? addApproveDiary(brand_id) : removeApproveDiary(brand_id);
    console.log('impression error');
    $popup.error('Error');
  });
}

const diaryWideRedirect = function() {
  // if not document.location.pathname.indexOf('/edit/'
  if(document.location.pathname.indexOf('/edit/') < 0)
  router.push({
    path: '/diaries-wide/' + diary_id.value
  });
}



const loadData = async (id) => {
  let data = await $api.getDiary(id);
  is_loaded.value = true;
  return data;
}



const { pending: is_loading,  data: diary_data, error: diary_error } = await useLazyAsyncData('view_diary_data_' + diary_id.value, async () => await loadData(diary_id.value))





if(diary_data.value)
  setActiveWeek(route_week_id.value);


watch(diary_data, async ( new_diary_data) => {
  setActiveWeek(route_week_id.value);
  useHead(useNuxtApp().$head.getForDiary(diary_data.value, week_active.value, diary_data.value?.is_deindex));
  showCreadeWeekIfEmpty();
})


watch(router.currentRoute, (newCurrentRoute, oldCurrentRoute) => {
  // router.currentRoute.value.params.week
  // week_id.value = new_week_id;
  // console.log('warch ');
  // console.log(newCurrentRoute.params.week);
  // console.log(new_route.params);
  // route_week_id = new_route.params['week'] ?? null;
  route_week_id = newCurrentRoute.params.week;
  setActiveWeek(route_week_id);
  useHead(useNuxtApp().$head.getForDiary(diary_data.value, week_active.value, diary_data.value?.is_deindex));
}, {deep: true})

// watch(route, (new_route, old_route) => {
//   // week_id.value = new_week_id;
//   console.log('new_route.params');
//   console.log(new_route.params);
//   route_week_id = new_route.params['week'] ?? null;
//   setActiveWeek(route_week_id);
// }, {deep: true})

useHead(
  useNuxtApp().$head.getForDiary(diary_data.value, week_active.value, diary_data.value?.is_deindex),
);

onUnmounted(() => {
  // if(diary_error.value) diary_error.value = null;
  if(diary_data.value) diary_data.value = null;
})



onMounted(() => {

  showCreadeWeekIfEmpty();

  if(useExperimentsDiaryWide().value){
    diaryWideRedirect();
  }

})









</script>


<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}


</style>