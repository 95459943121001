<template>


  <ClientOnly>
    <Teleport v-if="active" to="#app">
      <UiModal
        :close-button-name="close"
        :is-transparent="true"
        :is-auto-height="true"
        :width="'100%'"
        @close="closeModal()"         
        >        
          
        <div class="diary_finish">

          <div class="top">
            <img src="/images/diary_finish.svg"/>
            <div>
              <h1>{{ $t('diary_finished_landing_title') }}</h1>
              <p>{{ $t('diary_finished_landing_subtitle') }}</p>
            </div>
          </div>

          <div class="body">

            <div class="item">
              <h2>{{ $t('diary_finished_landing_title2') }}</h2>
              <p>{{ $t('diary_finished_landing_subtitle2') }}</p>

              <UiButton
                :name="$t('main_menu_giveaways')"
                :tag="'NuxtLink'"
                :to="'/giveaways'"
                :type="'primary'"
                @click="closeModal"
                />        

            </div>

            <div class="item">
              <h2>{{ $t('diary_finished_landing_title3') }}</h2>
              <p>{{ $t('diary_finished_landing_subtitle3') }}</p>
              
              <UiButton
                :name="$t('main_menu_start_diary')"
                :tag="'NuxtLink'"
                :to="'/diaries/edit/new'"
                :type="'primary'"
                @click="closeModal"
                />       

            </div>

          </div>
          </div>

      </UiModal>
    </Teleport>
  </ClientOnly>

  
</template>

<script setup>

const props = defineProps({
  active: {
    type: Boolean,
    default: false
  }
})

const isLoadingSave = ref(false);
const emits = defineEmits(['close'])

const closeModal = () => {
  emits('close');
}
 
 

</script>

<style scoped>
 
.diary_finish{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.diary_finish .top{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  flex-direction: column;
}
.diary_finish .top img{max-width: 210px;margin-bottom: 10px;margin-left: -15px;}
.diary_finish .top h1{color: white!important;margin-bottom: 5px;}
.diary_finish .top p{

}


.diary_finish .body{
  border-radius: 10px;
  background-color:var(--un-background-color-gray);
  padding: 20px;
  display: flex;
  width: 500px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  min-width: 626px;
  margin-bottom: 20px;
  text-align: center;
}

.diary_finish .body .item{
  flex-grow: 1;
  flex-basis: 0;
  margin: 0 10px;
}
.diary_finish .body h2{

}
.diary_finish .body p{
  color: var(--un-text-color);
}




@media (max-width: 768px) {
  .diary_finish .body{
    flex-direction: column;
    width: auto;
    min-width: auto;
  }
  .diary_finish .body .item{
    margin: 10px 0px;
  }
}


</style>
