<template>


  <ClientOnly>
    <Teleport v-if="active" to="#app">
      <UiModal
        
        :title="$t('cover_picker_popup_title')"   
        :close-button-name="close"
        :is-transparent="true"
        :is-auto-height="true"
        :width="'100%'"
        :max-width="'500px'"
        @close="closeModal()"         
        >        
            
          <div class="covers">

            <template
              v-for="(photo) in list"          
              >

              <img class="cover" @click="setCover(photo)" :src="photo.size_s" />

            </template>
          
          </div>
        </UiModal>
    </Teleport>
  </ClientOnly>

  
</template>

<script setup>

const { $api, $popup } = useNuxtApp();

const props = defineProps({
  diaryId: {
    type: Number
  },
  list: {
    type: Array,
    default: []
  },
  active: {
    type: Boolean,
    default: false
  }
})

const isLoadingSave = ref(false);
const emits = defineEmits(['close'])

const closeModal = () => {
  emits('close');
}
 

const setCover = (photo) => {    

  isLoadingSave.value = true;
  closeModal();

  $api.putDiaryCover(props.diaryId, photo.id) 
  .then((res) => {      
    $popup.success('Saved');  
    isLoadingSave.value = false; 
    
  })
  .catch(function (error) { 
    $popup.error('Error');    
    isLoadingSave.value = false;
  });

}


</script>

<style scoped>
 
.covers{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: 500px;
}
.covers .cover{
  width: calc(100% / 5 - 10px);
  border-radius: 5px;
  cursor: pointer;
  aspect-ratio: 1/1;
  object-fit: cover;
}


</style>
